/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { jsx, Styled, SxStyleProp } from "theme-ui";

import { MainMenuQuery } from "../../../__generated__/queries";
import logo from "../../images/especes-en-danger.png";
import { AnimalType } from "../../templates/Animal/Header";

type ItemId = "subaquatic" | "terrestrial";

interface Item {
  id: ItemId;
  content: React.ReactNode;
  to: string;
}

const linkSx: SxStyleProp = {
  border: "none",
  color: "#fff",
  display: "block",
  fontFamily: "heading",
  fontSize: [0, 0, 2],
  letterSpacing: [0, 0, 0, ".1em"],
  lineHeight: 1.2,
  paddingBottom: ".625rem",
  paddingLeft: 3,
  paddingRight: 3,
  paddingTop: ".75rem",
  textAlign: "center",
  textShadow: "2px 2px 10px rgba(0,0,0,.3)",
  textTransform: "uppercase",
  whiteSpace: ["wrap", "nowrap"],
  "&:hover": {
    backgroundColor: "accent",
    color: "#fff",
    textShadow: "none",
  },
};

const activeLinkSx: SxStyleProp = {
  ...linkSx,
  backgroundColor: "accent",
  color: "#fff",
  textShadow: "none",
};

const listSx: SxStyleProp = {
  flex: 1,
  marginRight: [0, 0, 0, 3],
  "&:last-of-type": {
    marginRight: 0,
  },
};

const items: Item[] = [
  {
    id: "subaquatic",
    content: (
      <React.Fragment>
        <span sx={{ display: ["none", "none", "inline"] }}>Espèces</span>{" "}
        marines
      </React.Fragment>
    ),
    to: "/especes-marines",
  },
  {
    id: "terrestrial",
    content: (
      <React.Fragment>
        <span sx={{ display: ["none", "none", "inline"] }}>Espèces</span>{" "}
        terrestres
      </React.Fragment>
    ),
    to: "/especes-terrestres",
  },
];

const MainMenu: React.FC<{ theme?: AnimalType }> = ({ theme }) => {
  const data = useStaticQuery<MainMenuQuery>(graphql`
    query MainMenuQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  let activeItemId: ItemId;

  switch (theme) {
    case "aquatic":
    case "subaquatic":
      activeItemId = "subaquatic";
      break;
    case "terrestrial":
      activeItemId = "terrestrial";
  }

  return (
    <div
      sx={{
        paddingTop: [2, 2, 3],
        paddingBottom: [2, 2, 3],
      }}
    >
      <div
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Link to={"/"}>
            <img
              alt={data.site?.siteMetadata?.title || ""}
              src={logo}
              sx={{
                display: "block",
                height: ["34px", "44px", "44px", "auto"],
              }}
            />
          </Link>
        </div>
        <ul
          sx={{
            flex: 0,
            display: "flex",
            margin: 0,
            padding: 0,
            listStyle: "none",
          }}
        >
          {items.map(({ id, content, to }) => (
            <li key={id} sx={listSx}>
              <Styled.a
                as={Link}
                sx={activeItemId === id ? activeLinkSx : linkSx}
                // @ts-ignore
                to={to}
              >
                {content}
              </Styled.a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(MainMenu);
