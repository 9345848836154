/** @jsx jsx */
import { memo } from "react";
import { jsx } from "theme-ui";

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div
    sx={{
      boxSizing: "border-box",
      margin: "0 auto",
      paddingBottom: 0,
      paddingLeft: [3, 5],
      paddingRight: [3, 5],
      paddingTop: 0,
      width: ["auto", "570px", "750px", "990px", "1170px"],
    }}
  >
    {children}
  </div>
);

export default memo(Container);
