import { Theme } from "theme-ui";

const theme: Theme = {
  fonts: {
    body: "lucioleregular, Arial, sans-serif",
    bold: "luciolebold, Arial, sans-serif",
    heading: "intelo_altextrabold, Arial, sans-serif",
  },
  fontSizes: [
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "32px",
    "48px",
    "64px",
    "72px",
    "96px",
  ],
  fontWeights: { body: 400, heading: 400 },
  lineHeights: { body: 1.75, heading: 1.25 },
  space: [
    "0px",
    "4px",
    "8px",
    "16px",
    "32px",
    "64px",
    "92px",
    "128px",
    "180px",
    "256px",
    "360px",
    "512px",
  ],
  borderWidths: ["0px", "1px", "2px", "4px"],
  colors: {
    text: "#000",
    background: "#fff",
    accent: "#fa720e",
    muted: "#f8f7f4",
    primary: "#000",
  },
  breakpoints: ["576px", "768px", "992px", "1200px"],
  styles: {
    root: {
      color: "text",
      fontFamily: "body",
      fontSize: [1, 1, 1, 2, 2],
      lineHeight: "body",
    },
    h1: {
      fontFamily: "heading",
      lineHeight: "heading",
      marginTop: 0,
    },
    h2: {
      fontFamily: "heading",
      lineHeight: "heading",
      marginTop: 0,
    },
    h3: {
      fontFamily: "heading",
      lineHeight: "heading",
      marginTop: 0,
    },
    h4: {
      fontFamily: "heading",
      lineHeight: "heading",
      marginTop: 0,
    },
    h5: {
      fontFamily: "heading",
      lineHeight: "heading",
      marginTop: 0,
    },
    h6: {
      fontFamily: "heading",
      lineHeight: "heading",
      marginTop: 0,
    },
    p: {
      marginBottom: 4,
      marginTop: 0,
      ":last-child": {
        marginBottom: 0,
      },
    },
    ul: {
      listStyle: "none",
      marginBottom: 4,
      ml: 0,
      marginRight: 0,
      marginTop: 0,
      padding: 0,
      ":last-child": {
        marginBottom: 0,
      },
    },
    li: {
      marginBottom: 3,
      paddingLeft: "2.5ex",
      position: "relative",
      "::before": {
        color: "accent",
        content: '"—"',
        left: 0,
        marginRight: ".75ex",
        position: "absolute",
      },
      ":last-child": {
        marginBottom: 0,
      },
    },
    a: {
      color: "text",
      borderBottomColor: "accent",
      borderBottomStyle: "solid",
      borderBottomWidth: 2,
      textDecoration: "none",
      ":hover": {
        color: "accent",
      },
    },
    b: {
      fontFamily: "bold",
      fontWeight: "normal",
    },
    strong: {
      fontFamily: "bold",
      fontWeight: "normal",
    },
  },
};

export default theme;
